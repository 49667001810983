import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import React from 'react';
import useStyles from '../../Totalizer/CardTotalizer/styles';

export interface ItemProps {
  title: string;
  color: string;
  content?: string;
  loading?: boolean;
}

function Item({ title, color, content, loading }: ItemProps) {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box className={classes.content}>
        <Grid item className={classes.contentTotal}>
          <Box className={classes.boxTotal}>
            <Typography className={classes.subtitle} variant="subtitle1">
              {title}
            </Typography>
            {loading ? (
              <Typography className={classes.h3} variant="h3">
                <Skeleton width={100} />
              </Typography>
            ) : (
              <Typography className={classes.h3} color={color} variant="h3">
                {content}
              </Typography>
            )}
          </Box>
        </Grid>
      </Box>
    </Card>
  );
}

export default Item;
