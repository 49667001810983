import React from 'react';
import { BrokerData } from '../../../models/brokerData';
import { Content, ContentRow } from './styles';
import { hour as dateFormatter } from '../../../util/formatter';
import palette from '../../../theme/palette';
import Item from './Item';

interface BrokerDataProps {
  data?: BrokerData;
  loading: boolean;
}

interface BodyProps {
  data?: BrokerData;
  loading: boolean;
}

function Body({ data, loading }: BodyProps) {
  const hora_calculo = {
    title: 'Hora do Cálculo',
    color: palette.status.portability.color,
    content: !data ? '-' : dateFormatter(data.hora_calculo),
    loading,
  };

  const numero_optimo_workers = {
    title: 'Qtd Ótima',
    color: palette.status.portability.color,
    content: !data ? '-' : data.numero_optimo_workers.toString(),
    loading,
  };

  const tempo_medio = {
    title: 'Tempo Médio',
    color: !data
      ? palette.status.portability.color
      : data.tempo_medio >= data.tempo_medio_maximo
      ? palette.status.generalFailures.color
      : palette.status.stardardFlow.color,
    content: !data ? '-' : data.tempo_medio.toString(),
    loading,
  };

  const tps_corrente = {
    title: 'TPS',
    color: !data
      ? palette.status.portability.color
      : data.tps_corrente < data.tps_minimo
      ? palette.status.generalFailures.color
      : palette.status.stardardFlow.color,
    content: !data ? '-' : data.tps_corrente.toString(),
    loading,
  };

  const taxa_falha = {
    title: 'Taxa de Falha',
    color: !data
      ? palette.status.portability.color
      : data.taxa_falha >= data.taxa_falha_maxima
      ? palette.status.generalFailures.color
      : palette.status.stardardFlow.color,
    content: !data ? '-' : data.taxa_falha.toString(),
    loading,
  };

  return (
    <ContentRow>
      <Item {...hora_calculo} />
      <Item {...numero_optimo_workers} />
      <Item {...tempo_medio} />
      <Item {...tps_corrente} />
      <Item {...taxa_falha} />
    </ContentRow>
  );
}

function BrokerDataCard({ data, loading }: BrokerDataProps) {
  return (
    <Content>
      <Body data={data} loading={loading} />
    </Content>
  );
}

export default BrokerDataCard;
