import {
  Container,
  Data,
  DataInfo,
  Divider,
} from 'components/StyledComponents';
import { convertDateInString as dateFormatter } from 'util/formatter';
import { Skeleton } from '@mui/material';
import React from 'react';
import { DataText, DataTextName } from './styles';
import { BrokerLog } from '../../../../../models/brokerLog';

export interface ItemProps {
  data?: BrokerLog;
  loading?: boolean;
}

function Item({ data, loading }: ItemProps) {
  return (
    <Container>
      <Data>
        {loading ? (
          <DataInfo>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={85} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
            <DataText>
              <Skeleton width={90} />
            </DataText>
          </DataInfo>
        ) : (
          data && (
            <DataInfo>
              <DataText style={{ width: 200 }}>
                {dateFormatter(data.data)}
              </DataText>
              <DataTextName style={{ width: 100 }}>{data.usuario}</DataTextName>
              <DataTextName style={{ width: '40%', maxWidth: '40%' }}>
                {data.mensagem}
              </DataTextName>
              <DataTextName style={{ width: 50 }}>
                {data.quantidade_workers_anterior}
              </DataTextName>
              <DataTextName style={{ width: 50 }}>
                {data.quantidade_workers_nova}
              </DataTextName>
              <DataTextName style={{ width: 50 }}>
                {data.tps_corrente}
              </DataTextName>
              <DataTextName style={{ width: 50 }}>
                {data.tempo_medio_corrente}
              </DataTextName>
              <DataTextName style={{ width: 50 }}>
                {data.taxa_falha_corrente}
              </DataTextName>
            </DataInfo>
          )
        )}
      </Data>
      <Divider />
    </Container>
  );
}

export default Item;
