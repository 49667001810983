import { GlobalVariable } from 'models/globalVariable';
import api from 'services/api';
import userSWR from 'swr';

export const GlobalVariableFetch = (key: string) => {
  const { data, mutate: mutateGlobalVariable } = userSWR<GlobalVariable>(
    `global_variable_${key}`,
    async (): Promise<GlobalVariable> => {
      const { data: response } = await api.get(`/api/variaveis-globais/${key}`);

      return response as GlobalVariable;
    },
  );

  return { data, mutateGlobalVariable };
};
