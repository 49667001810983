import { Box, Card, Grid, Icon, Skeleton, Typography } from '@mui/material';
import React from 'react';
import useStyles, { BoxIcon, Content } from '../CardTypeIndicator/styles';
import { cardConfig } from './cardConfig';
import RelevantDataPanel from './RelevantDataPanel';
import { HealthCheckStatusFetch } from '../../../../fetches/healthCheckStatusFetch';

interface HealthCheckPanelProps {
  mechanism: string;
}
function HealthCheckPanel({ mechanism }: HealthCheckPanelProps) {
  const { data: healthData } = HealthCheckStatusFetch(mechanism);
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      {healthData ? (
        <>
          <BoxIcon color={cardConfig[healthData.status].boxIconColor}>
            <Typography
              className={classes.subtitle}
              variant="body2"
              color={cardConfig[healthData.status].subtitleColor}
              sx={{
                writingMode: 'vertical-rl',
                transform: 'rotate(180deg)',
                fontSize: '0.9rem',
                fontWeight: 'bold',
              }}
            >
              {healthData.title}
            </Typography>
          </BoxIcon>
          <Content color={cardConfig[healthData.status].contentColor}>
            <Grid item className={classes.contentTotal}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <Icon
                  component={cardConfig[healthData.status].icon}
                  fontSize="large"
                  sx={{
                    color: cardConfig[healthData.status].colorIcon,
                  }}
                />
                <Typography
                  className={classes.title}
                  color={cardConfig[healthData.status].titleColor}
                  variant="subtitle1"
                >
                  {cardConfig[healthData.status].title}
                </Typography>
              </Box>
            </Grid>
            <RelevantDataPanel relevantData={healthData.relevantData} />
          </Content>
        </>
      ) : (
        <Skeleton animation="wave" height="134px" width="100%" />
      )}
    </Card>
  );
}

export default HealthCheckPanel;
