import { List } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import { Container } from 'components/CardList';
import { Header } from './styles';
import MobileItem from './components/MobileItem';
import Item from './components/Item';
import { BrokerLog } from '../../../models/brokerLog';

interface LogListProps {
  logs?: BrokerLog[];
  loading: boolean;
}

interface BodyProps {
  logs: BrokerLog[];
  isMobile: boolean;
  loading: boolean;
}
interface LoadingListProps {
  isTableOrLess: boolean;
}

function LoadingList({ isTableOrLess }: LoadingListProps) {
  return isTableOrLess ? (
    <>
      <MobileItem loading />
      <MobileItem loading />
    </>
  ) : (
    <>
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
      <Item loading />
    </>
  );
}

function Body({ logs, isMobile, loading }: BodyProps) {
  return (
    <List>
      {logs.length ? (
        logs.map(item =>
          isMobile ? (
            <MobileItem key={item.data} data={item} />
          ) : (
            <Item key={item.data} data={item} loading={loading} />
          ),
        )
      ) : (
        <h3 style={{ marginTop: 15 }}>Nenhuma base encontrada</h3>
      )}
    </List>
  );
}

function LogList({ logs, loading }: LogListProps) {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  return (
    <Container>
      {!isTableOrLess && (
        <Header>
          <span>Data</span>
          <span>Usuário</span>
          <span>Mensagem</span>
          <span style={{ marginRight: 50 }}>Qtd Ant</span>
          <span style={{ marginRight: 70 }}>Qtd Nova</span>
          <span style={{ marginRight: 70 }}>TPS</span>
          <span style={{ marginRight: 50 }}>Tempo</span>
          <span style={{ marginRight: 50 }}>Falhas%</span>
        </Header>
      )}
      {logs ? (
        <Body logs={logs} isMobile={isTableOrLess} loading={loading} />
      ) : (
        <LoadingList isTableOrLess={isTableOrLess} />
      )}
    </Container>
  );
}

export default LogList;
