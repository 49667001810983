import palette from 'theme/palette';
import { CrisisAlert } from '@mui/icons-material';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

export interface HealthData {
  title: string;
  status: Status;
  relevantData: RelevantData[];
}
export interface RelevantData {
  title: string;
  value: string;
  status: Status;
}

interface CardConfig {
  title: string;
  icon: React.ElementType;
  colorIcon: string;
  colorRelevantDataTitle: string;
  colorRelevantDataValue: string;
  titleColor: string;
  subtitleColor: string;
  boxIconColor: string;
  contentColor: string;
}

export type Status = 'SUCCESS' | 'ALERT' | 'FAILURE' | 'OFFLINE';

interface CardTypes {
  SUCCESS: CardConfig;
  ALERT: CardConfig;
  FAILURE: CardConfig;
  OFFLINE: CardConfig;
  [key: string]: CardConfig;
}

export const cardConfig: CardTypes = {
  SUCCESS: {
    title: 'Sem Problemas',
    icon: CheckCircleOutlineIcon,
    titleColor: palette.success.main,
    subtitleColor: palette.white,
    colorIcon: palette.success.main,
    colorRelevantDataTitle: palette.success.main,
    colorRelevantDataValue: palette.success.main,
    boxIconColor: palette.success.dark,
    contentColor: palette.success.light,
  },
  ALERT: {
    title: 'Alguns Problemas',
    icon: TroubleshootIcon,
    titleColor: palette.warning.main,
    subtitleColor: palette.white,
    colorIcon: palette.warning.main,
    colorRelevantDataTitle: palette.warning.main,
    colorRelevantDataValue: palette.warning.main,
    boxIconColor: palette.warning.dark,
    contentColor: palette.warning.light,
  },
  FAILURE: {
    title: 'Falha!',
    icon: CrisisAlert,
    titleColor: palette.error.main,
    subtitleColor: palette.white,
    colorIcon: palette.error.main,
    colorRelevantDataTitle: palette.error.main,
    colorRelevantDataValue: palette.error.main,
    boxIconColor: palette.error.dark,
    contentColor: palette.error.light,
  },
  OFFLINE: {
    title: 'Offline',
    icon: FlashOffIcon,
    titleColor: palette.offline.main,
    subtitleColor: palette.white,
    colorIcon: palette.offline.main,
    colorRelevantDataTitle: palette.offline.main,
    colorRelevantDataValue: palette.offline.main,
    boxIconColor: palette.offline.dark,
    contentColor: palette.offline.light,
  },
};
