export const relatorioGerencial = 'web.vendas.relatorio_gerencial';
export const transacoes = 'web.vendas.transacoes';
export const curadorias = 'web.operacao.curadorias';
export const operacao = 'web.operacao.envio_simcard';
export const gestaoSIMCards = 'web.operacao.gestao_simcards';
export const dominios = 'web.operacao.dominios';
export const portabilidadeValidar = 'web.portabilidade.validar';
export const flashSale = 'web.marketing.flash_sale';
export const relatorioAbandonoFluxo = 'web.marketing.relatorio_abandono_fluxo';
export const avaliacoes = 'web.marketing.avaliacoes';
export const gestaoFuncao = 'web.gestao.funcao';
export const gestaoUsuarios = 'web.gestao.usuarios';
export const gestaoSetups = 'web.gestao.setups';
export const vendedorVirtual = 'web.gestao.vendedor_virtual';
export const perfil = 'web.gestao.usuario.perfil';
export const errosIntegracao = 'web.gestao.erros_integracao';
export const vendaExtrairRelatorio = 'web.vendas.transacoes.extrair_relatorio';
export const vendaStatusMovimentacoesSIMCard =
  'web.vendas.transacoes.status.movimentacoes_simcard';
export const vendaEditarDados = 'web.vendas.transacoes.editar_dados';
export const vendaEditarStatus = 'web.vendas.transacoes.editar_status';
export const vendaEditar = 'web.vendas.transacoes.editar';
export const trocaSenha = 'web.gestao.usuario.trocar_senha';
export const tratamentoErros = 'web.operacao.tratamento_erros';
export const portabilidadeTratamento = 'web.portabilidade.tratamento';
export const exibirValorFinanceiro =
  'web.vendas.relatorio_gerencial.exibir_valor_financeiro';
export const exibirTotalizadoresNaoVendasEReprocessadas =
  'web.vendas.relatorio_gerencial.exibir_nao_vendas_e_reprocessadas';
export const gestaoAcessos = 'web.gestao.acessos';
export const gestaoManual = 'web.gestao.manual';
export const acessarOmegaMessenger = 'web.gestao.acessos.omega_messenger';
export const acessarKibana = 'web.gestao.acessos.kibana';
export const acessarGrafana = 'web.gestao.acessos.grafana';
export const basesAfiliados = 'web.marketing.bases_afiliados';
export const relatoriosEspecias = 'web.vendas.relatorios_especiais';
export const relatorioDeErros = 'web.gestao.relatorio_erros';
export const gestaoPacotes = 'web.marketing.gestao_pacotes';
export const distribuicaoUtm = 'web.marketing.distribuicao_utm';
export const execucaoElegibilidade = 'web.operacoes.execucao_elegibilidade';
export const cmsLP = 'web.marketing.cms_lp';
export const correcaoVendas = 'web.operacao.correcao_de_vendas';
export const linksOfertas = 'web.marketing.links_ofertas';
export const gestaoPlanos = 'web.gestao.planos';
export const planosSincronizar = 'web.gestao.planos.sincronizar';
export const gestaoMeta = 'web.gestao.meta';
export const gestaoSetupCategoriaVendasReprocessamento =
  'web.gestao.setups_categoria_vendas_reprocessamento';
export const relatoriosEspeciaisPedidos =
  'web.vendas.relatorios_especiais_pedidos';
export const relatoriosEspeciaisAtivados =
  'web.vendas.relatorios_especiais_ativados';
export const relatoriosEspeciaisCustoEnvio =
  'web.vendas.relatorios_especiais_custo_envio';
export const relatoriosEspeciaisReprocessamentoManual =
  'web.vendas.relatorios_especiais_reprocessamento_manual';
export const reprocessamentoTotal = 'web.marketing.reprocessamento_total';
export const definicaoDeUtm = 'web.marketing.definicao_de_utm';
export const importarBaseEngajamento =
  'web.marketing.importar_base_engajamento';
export const dadosBlacklist = 'web.operacao.dados_blacklist';
export const acompanhamentoBroker = 'web.broker.acompanhamento';
export const atualizarWorkersBroker = 'web.broker.atualizar_workers';
export const atualizarTpsBroker = 'web.broker.atualizar_tps';
export const rebalancearWorkersBroker = 'web.broker.rebalancear_workers';
export const visualizarPainelDeSaude =
  'web.vendas.relatorio_gerencial.painel_saude';
