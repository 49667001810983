import { useEffect, useState } from 'react';
import CardTotalizer from './CardTotalizer';
import { Content, ContentRow } from './styles';
import { useAuth } from '../../../hooks/auth';
import {
  atualizarWorkersBroker,
  atualizarTpsBroker,
  rebalancearWorkersBroker,
} from '../../../constants/permissions';
import { useTotalizersFetch } from './hooks/useTotalizersFetch';

function Totalizer() {
  const { workers, tps, dataLastRebalancing } = useTotalizersFetch();

  const { hasPermission } = useAuth();

  const [showEditWorkers, setShowEditWorkers] = useState(false);
  const [showEditTPS, setShowEditTPS] = useState(false);
  const [showDispatchRebalancing, setShowDispatchRebalancing] = useState(false);

  useEffect(() => {
    setShowEditWorkers(hasPermission(atualizarWorkersBroker));
    setShowEditTPS(hasPermission(atualizarTpsBroker));
    setShowDispatchRebalancing(hasPermission(rebalancearWorkersBroker));
  }, [hasPermission]);

  return (
    <Content>
      <ContentRow>
        <CardTotalizer
          type="workers"
          subtitle="Quantidade"
          title="de Workers"
          data={workers}
          customButton={showEditWorkers ? 'editar-robos' : null}
        />
        <CardTotalizer
          type="tps"
          subtitle="TPS"
          title="Alvo"
          data={tps}
          customButton={showEditTPS ? 'editar-tps' : null}
        />
        <CardTotalizer
          type="ultimo_rebalanceamento"
          subtitle="Ultimo"
          title="Rebalanceamento"
          data={dataLastRebalancing}
          customButton={
            showDispatchRebalancing ? 'disparar-balanceamento' : null
          }
        />
      </ContentRow>
    </Content>
  );
}

export default Totalizer;
