import { Header } from 'pages/Sale/style';
import { CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { ReactComponent as MigrationIcon } from 'assets/icons/small/migration.svg';
import { ReactComponent as PortabilityIcon } from 'assets/icons/small/portability.svg';
import { ReactComponent as NewLineIcon } from 'assets/icons/small/new_line.svg';
import { ReactComponent as AdvancedFiltersIcon } from 'assets/icons/small/advanced_filters.svg';
import useBreakpoint from 'hooks/useBreakpoint';
import Filters from './Filters';
import AverageSales from './reports/AverageSales';
import ProjectedSales from './reports/ProjectedSales';
import NoSales from './reports/NoSales';
import Reprocessed from './reports/Reprocessed';
import TotalByMedia from './reports/TotalByMedia';
import HourByHour from './reports/HourByHour';
import ThirtyDayEvolution from './reports/ThirtyDayEvolution';
import TwelveMonthsEvolution from './reports/TwelveMonthsEvolution';
import EvolutionByTypeOfMedia from './reports/EvolutionByTypeOfMedia';
import { BoxDescriptionType, BoxHeader, MobileFilterButton } from './styles';
import CardTypeIndicator from './reports/CardTypeIndicator';
import { useManagementReport } from './hooks/useManagementReport';
import HealthCheckPanel from './reports/HealthCheckPanel';

function ManagementReport() {
  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(1024);

  const {
    anchorElement,
    setAnchorElement,
    startFetch,
    endFetch,
    day,
    showNonSalesAndReprocessedTotalizers,
    showHealthPanel,
    showBrokerHealthPanel,
    showEngagementHealthPanel,
    showEligibilityHealthPanel,
    showActivationHealthPanel,
    gridComponentSize,
    handleClick,
  } = useManagementReport();

  if (!startFetch || !endFetch || !day) {
    return <h1>Carregando... </h1>;
  }
  return (
    <>
      <Header isDesktop={!isTableOrLess}>
        <BoxHeader>
          <CardHeader
            title="Relatório Gerencial"
            subheader="Módulo para acompanhamento"
          />
          {isTableOrLess && (
            <MobileFilterButton onClick={handleClick}>
              <AdvancedFiltersIcon />
            </MobileFilterButton>
          )}
        </BoxHeader>

        <Filters
          showReprocessedSalesButton={showNonSalesAndReprocessedTotalizers}
          anchorElement={anchorElement}
          setAnchorElement={setAnchorElement}
          handleClick={handleClick}
        />
      </Header>

      <CardContent>
        <Grid container spacing={2}>
          <Grid
            item
            xl={gridComponentSize}
            lg={gridComponentSize}
            md={6}
            xs={12}
          >
            <CardTypeIndicator type="CONTROLE" />
          </Grid>
          <Grid
            item
            xl={gridComponentSize}
            lg={gridComponentSize}
            md={6}
            xs={12}
          >
            <CardTypeIndicator type="EXPRESS" />
          </Grid>
          <Grid
            item
            xl={gridComponentSize}
            lg={gridComponentSize}
            md={6}
            xs={12}
          >
            <CardTypeIndicator type="POS_PAGO" />
          </Grid>
          {!isTableOrLess ? (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {showNonSalesAndReprocessedTotalizers ? (
                <>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <NoSales />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <CardTypeIndicator type="REAL" />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <AverageSales />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <ProjectedSales />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <Reprocessed />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xl={4} lg={4} md={6} xs={12}>
                    <CardTypeIndicator type="REAL" />
                  </Grid>
                  <Grid item xl={4} lg={4} md={6} xs={12}>
                    <AverageSales />
                  </Grid>
                  <Grid item xl={4} lg={4} md={6} xs={12}>
                    <ProjectedSales />
                  </Grid>
                </>
              )}
            </>
          ) : (
            <>
              {showNonSalesAndReprocessedTotalizers && (
                <>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <NoSales />
                  </Grid>
                  <Grid item xl={3} lg={3} md={6} xs={12}>
                    <Reprocessed />
                  </Grid>
                </>
              )}
              <Grid item xl={3} lg={3} md={6} xs={12}>
                <ProjectedSales />
              </Grid>
              <Grid item xl={3} lg={3} md={6} xs={12}>
                <AverageSales />
              </Grid>
              <Grid item xl={3} lg={3} md={6} xs={12}>
                <CardTypeIndicator type="REAL" />
              </Grid>
            </>
          )}
          <Grid container ml={2} mt={2} gap={2}>
            <BoxDescriptionType>
              <MigrationIcon fill="gray" />
              <Typography variant="body2">Migração</Typography>
            </BoxDescriptionType>
            <BoxDescriptionType>
              <NewLineIcon fill="gray" />
              <Typography variant="body2">Nova linha</Typography>
            </BoxDescriptionType>
            <BoxDescriptionType>
              <PortabilityIcon fill="gray" />
              <Typography variant="body2">Portabilidade</Typography>
            </BoxDescriptionType>
          </Grid>
          {showHealthPanel && (
            <>
              <Grid item lg={12} md={12} xl={12} xs={12}>
                <h2>Health Checks</h2>
              </Grid>
              {showBrokerHealthPanel && (
                <Grid item xl={3} lg={3} md={6} xs={12}>
                  <HealthCheckPanel mechanism="broker" />
                </Grid>
              )}
              {showEngagementHealthPanel && (
                <Grid item xl={3} lg={3} md={6} xs={12}>
                  <HealthCheckPanel mechanism="engajador" />
                </Grid>
              )}
              {showEligibilityHealthPanel && (
                <Grid item xl={3} lg={3} md={6} xs={12}>
                  <HealthCheckPanel mechanism="elegibilidades" />
                </Grid>
              )}
              {showActivationHealthPanel && (
                <Grid item xl={3} lg={3} md={6} xs={12}>
                  <HealthCheckPanel mechanism="ativacoes" />
                </Grid>
              )}
            </>
          )}
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <HourByHour day={day} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <ThirtyDayEvolution day={day} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <TwelveMonthsEvolution day={day} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <EvolutionByTypeOfMedia day={day} />
          </Grid>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            <TotalByMedia />
          </Grid>
        </Grid>
      </CardContent>
    </>
  );
}

export default ManagementReport;
