import { ReactComponent as StarsIcon } from 'assets/icons/small/robot.svg';
import { ReactComponent as ValuedSales } from 'assets/icons/small/tps.svg';
import { ReactComponent as UnvaluedSales } from 'assets/icons/small/clock.svg';
import palette from 'theme/palette';
import { FunctionComponent, SVGProps } from 'react';

export type Types = 'workers' | 'tps' | 'ultimo_rebalanceamento';
export type CustomButtons =
  | 'editar-robos'
  | 'editar-tps'
  | 'disparar-balanceamento'
  | null;

interface CardConf {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  color: string;
}

interface CardTypes {
  workers: CardConf;
  tps: CardConf;
  ultimo_rebalanceamento: CardConf;
  [key: string]: CardConf;
}

const CardTypes: CardTypes = {
  workers: {
    icon: StarsIcon,
    color: palette.status.deliveryProblems.color,
  },
  tps: {
    icon: ValuedSales,
    color: palette.status.stardardFlow.color,
  },
  ultimo_rebalanceamento: {
    icon: UnvaluedSales,
    color: palette.status.generalFailures.color,
  },
};

export default CardTypes;
