import { FormControlLabel, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { SwitchIOS } from '../../../components/SwitchIOS';
import { GlobalVariableFetch } from '../../../fetches/globalVariableFetch';
import api from '../../../services/api';

function ToogleAutoRebalancing() {
  const key = 'BROKER_AUTO_REBALANCEAMENTO';
  const [loading, setLoading] = useState(true);
  const {
    data: autoRebalancingResponse,
    mutateGlobalVariable: mutateAutoRebalancing,
  } = GlobalVariableFetch(key);
  const [autoRebalancing, setAutoRebalancing] = useState(
    !!Number(autoRebalancingResponse?.valor ?? '0'),
  );

  useEffect(() => {
    if (autoRebalancingResponse) {
      setLoading(false);
    }
  }, [autoRebalancingResponse]);

  useEffect(() => {
    setAutoRebalancing(!!Number(autoRebalancingResponse?.valor ?? '0'));
  }, [autoRebalancingResponse, mutateAutoRebalancing]);

  const handleToggle = useCallback(() => {
    api
      .put(`/api/variaveis-globais/${key}`, {
        value: !autoRebalancing ? '1' : '0',
      })
      .then(async () => {
        setLoading(false);
        await mutateAutoRebalancing();
      })
      .catch(() => setLoading(false));
  }, [autoRebalancing, mutateAutoRebalancing]);

  const handleChangeToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAutoRebalancing(event.target.checked);
    handleToggle();
  };

  return (
    <FormControlLabel
      style={{ margin: '0 32px 0 0' }}
      label={
        <Typography variant="body2">Rebalanceamento automático</Typography>
      }
      control={
        loading ? (
          <Skeleton style={{ width: 64, height: 64, marginRight: 8 }} />
        ) : (
          <SwitchIOS
            size="medium"
            color="secondary"
            checked={autoRebalancing}
            onChange={handleChangeToggle}
          />
        )
      }
      labelPlacement="end"
    />
  );
}

export default ToogleAutoRebalancing;
