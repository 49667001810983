import {
  MobileContainer,
  MobileData,
  MobileDataLabel,
} from 'components/StyledComponents';
import { convertDateInString as dateFormatter } from 'util/formatter';
import { Skeleton } from '@mui/material';
import { GroupData } from 'components/MobileList';
import { MobileTextName } from 'pages/AffiliateBase/AffiliateBaseList/MobileItem/styles';
import React from 'react';
import { ItemProps } from '../Item';

function MobileItem({ data, loading }: ItemProps) {
  return (
    <MobileContainer>
      {loading ? (
        <>
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
          <Skeleton height={50} />
        </>
      ) : (
        data && (
          <>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Data</MobileDataLabel>
                <MobileTextName> {dateFormatter(data.data)}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Mensagem</MobileDataLabel>
                <MobileTextName>{data.mensagem}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Workers Anterior</MobileDataLabel>
                <MobileTextName>
                  {data.quantidade_workers_anterior}
                </MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Workers Novo</MobileDataLabel>
                <MobileTextName>{data.quantidade_workers_nova}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>TPS</MobileDataLabel>
                <MobileTextName>{data.tps_corrente}</MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Tempo Request</MobileDataLabel>
                <MobileTextName>{data.tempo_medio_corrente}</MobileTextName>
              </MobileData>
            </GroupData>
            <GroupData>
              <MobileData>
                <MobileDataLabel>Taxa Falha</MobileDataLabel>
                <MobileTextName>{data.taxa_falha_corrente}</MobileTextName>
              </MobileData>
              <MobileData>
                <MobileDataLabel>Usuário</MobileDataLabel>
                <MobileTextName>{data.usuario}</MobileTextName>
              </MobileData>
            </GroupData>
          </>
        )
      )}
    </MobileContainer>
  );
}

export default MobileItem;
