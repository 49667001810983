import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px 50px 15px 37px;
  color: rgba(0, 0, 0, 0.4);
  span {
    :nth-child(1) {
      width: 200px;
    }
    :nth-child(2) {
      width: 100px;
    }
    :nth-child(3) {
      width: 40%;
      max-width: 40%;
    }
    :nth-child(4) {
      width: 50px;
    }
    :nth-child(5) {
      width: 50px;
    }
    :nth-child(6) {
      width: 50px;
    }
    :nth-child(7) {
      width: 50px;
    }
    :nth-child(8) {
      width: 50px;
    }
    min-width: 50px;
    white-space: nowrap;
    font-size: 12px;
    align-self: flex-start;
    font-weight: 400;
  }
`;
