import api from 'services/api';
import useSWR from 'swr';
import { useState } from 'react';
import { BrokerData } from '../models/brokerData';

export const BrokerDataFetch = (cacheIgnore: boolean) => {
  const [loading, setLoading] = useState(true);
  const { data, mutate } = useSWR(
    `broker-data`,
    async (): Promise<BrokerData> => {
      const response = await api.get(
        `/integrations/messenger/dados-broker?ignorar-cache=${
          cacheIgnore ? 1 : 0
        }`,
      );
      return response.data as BrokerData;
    },
    {
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
    },
  );

  return { data, mutate, loading, setLoading };
};
