import { MessengerWorkersRunningFetch } from '../../../../fetches/brokerMonitoring';
import { GlobalVariableFetch } from '../../../../fetches/globalVariableFetch';

export function useTotalizersFetch() {
  const { data: workersRunningResponse, mutateWorkers } =
    MessengerWorkersRunningFetch('send-sms-tim');
  const { data: tpsResponse, mutateGlobalVariable: mutateTPS } =
    GlobalVariableFetch('BROKER_TPS_ALVO');
  const {
    data: dataLastRebalancingResponse,
    mutateGlobalVariable: mutateDataLastRebalancing,
  } = GlobalVariableFetch('BROKER_DATA_ULTIMO_REBALANCEAMENTO');

  const updateTotalizersData = async () => {
    await mutateWorkers();
    await mutateTPS();
    await mutateDataLastRebalancing();
  };

  return {
    workers: workersRunningResponse?.total.toString() || '0',
    tps: tpsResponse?.valor || '-',
    dataLastRebalancing: dataLastRebalancingResponse?.valor || '-',
    updateTotalizersData,
  };
}
