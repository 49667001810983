import React, { useState } from 'react';
import {
  Box,
  Button,
  CardHeader,
  Dialog,
  DialogActions,
  IconButton,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import { Container } from './style';
import { useTotalizersFetch } from '../../hooks/useTotalizersFetch';

function DispatchRebalancingForm() {
  const [showEditionWindow, setShowEditionWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();
  const { updateTotalizersData } = useTotalizersFetch();

  const handleUpdate = () => {
    setLoading(true);
    api
      .put(`/integrations/messenger/disparar-rebalanceamento`)
      .then(async () => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Sucesso',
          description: 'Rebalanceamento de Robôs disparado com sucesso!',
        });

        setShowEditionWindow(false);
        await updateTotalizersData();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao disparar rebalanceamento.',
            description: message,
          });
        setLoading(false);
      });
  };

  return (
    <Container>
      <IconButton onClick={() => setShowEditionWindow(true)}>
        <EventRepeatIcon />
      </IconButton>
      <Dialog
        open={showEditionWindow}
        onClose={() => setShowEditionWindow(false)}
        aria-labelledby="rebalancing"
        fullScreen={isTabletSizeOrSmaller}
      >
        <form>
          <Box padding="20px">
            <CardHeader
              id="rebalancing"
              title="Rebalancear Robôs"
              subheader="O número de robôs será rebalanceado, usando como métrica o TPS alvo, a taxa de erros e o TPS atingido do ultimo período."
              style={{ padding: 10, paddingBottom: 19 }}
            />
            <DialogActions
              style={{
                paddingTop: 14,
                justifyContent: 'space-between',
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Button
                onClick={() => {
                  setShowEditionWindow(false);
                }}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleUpdate}
                color="primary"
                type="button"
                variant="contained"
                disabled={loading}
              >
                Disparar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </Container>
  );
}

export default DispatchRebalancingForm;
