import { useStylesActionButtons } from 'components/StyledComponents';
import { BenefitsFetch } from 'fetches/benefitFetch';
import { LoyaltiesFetch } from 'fetches/loyaltyFetch';
import { ActivePackagesFetch } from 'fetches/packageFetch';
import { PlanByIdFetch } from 'fetches/planByIdFetch';
import { PlansFetch } from 'fetches/plansFetch';
import { useToast } from 'hooks/toast';
import useBreakpoint from 'hooks/useBreakpoint';
import { Benefit } from 'models/benefits';
import { Loyalty } from 'models/loyalties';
import { Package } from 'models/packages';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'services/api';
import { usePlanStore } from 'store/planStore';
import { downloadPdfFile } from 'util/blob';

export function useEditPlan() {
  const params = useParams();
  const { id } = params;
  const { data: plan, mutate } = PlanByIdFetch(parseInt(id || '0', 10));

  const { data: loyalties } = LoyaltiesFetch();
  const { data: packages } = ActivePackagesFetch();
  const { data: benefits } = BenefitsFetch();

  const fileRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);
  const [correspondingLoading, setCorrespondingLoading] = useState(false);
  const [loyaltyFiltered, setLoyaltyFiltered] = useState<Loyalty[]>([]);
  const [packagesFiltered, setPackagesFiltered] = useState<Package[]>([]);
  const [benefitsFiltered, setBenefitsFiltered] = useState<Benefit[]>([]);
  const [isOpenDialogPlanFidelity, setIsOpenDialogPlanFidelity] =
    useState(false);
  const [isOpenDialogPlanBenefit, setIsOpenDialogPlanBenefit] = useState(false);
  const [isOpenDialogPlanPackage, setIsOpenDialogPlanPackage] = useState(false);
  const [findSegmentPlans, setFindSegmentPlans] = useState('');
  const [loadingDownload, setLoadingDownload] = useState(false);

  const { data: plansResponse } = PlansFetch({
    segmento: findSegmentPlans,
    status: '1',
  });

  const planStore = usePlanStore(state => state.plan);
  const setPlanStore = usePlanStore(state => state.setPlan);

  const classes = useStylesActionButtons();

  const { isOnBreakpoint: isTableOrLess } = useBreakpoint(900);

  useEffect(() => {
    if (!plan) return;

    setPlanStore(plan);
    setLoading(false);
  }, [plan, setPlanStore]);

  useEffect(() => {
    setLoyaltyFiltered(() => {
      return (
        loyalties?.filter(diference => {
          if (!planStore.fidelidades) {
            return [];
          }
          return !planStore.fidelidades.find(
            fidelidade => fidelidade.tim_id === diference.tim_id,
          );
        }) || []
      );
    });

    setPackagesFiltered(() => {
      return (
        packages?.filter(diference => {
          if (!planStore.pacotes) {
            return [];
          }
          return !planStore.pacotes.find(pacote => pacote.id === diference.id);
        }) || []
      );
    });

    setBenefitsFiltered(() => {
      return (
        benefits?.filter(diference => {
          if (!planStore.beneficios) {
            return [];
          }
          return !planStore.beneficios.find(
            beneficio => beneficio.id === diference.id,
          );
        }) || []
      );
    });
  }, [planStore, packages, loyalties, benefits]);

  useEffect(() => {
    const segmentos: { [key: string]: string } = {
      CONTROLE: 'EXPRESS',
      EXPRESS: 'CONTROLE',
      POS_PAGO: 'POS_EXPRESS',
      POS_EXPRESS: 'POS_PAGO',
    };

    setFindSegmentPlans(segmentos[planStore.segmento]);
  }, [planStore.segmento]);

  useEffect(() => {
    planStore.id && setCorrespondingLoading(false);
  }, [planStore.id]);

  useEffect(() => {
    if (planStore?.correspondente_id) {
      setPlanStore({
        ...planStore,
        correspondente: planStore?.correspondente_id.toString(),
        correspondente_id: null,
      });
    }
  }, [planStore, planStore?.correspondente_id, setPlanStore]);

  const handleChangeNome = (valor: string) =>
    setPlanStore({ ...planStore, nome: valor });

  const handleChangeTimID = (valor: string) =>
    setPlanStore({ ...planStore, tim_id: valor });

  const handleChangeStatus = (valor: boolean) => {
    setPlanStore({ ...planStore, ativo: valor });
  };

  const handleChangeTravarSincronia = (valor: boolean) => {
    setPlanStore({ ...planStore, travar_sincronia: valor });
  };

  const handleChangeExibirEcommerce = (valor: boolean) => {
    setPlanStore({ ...planStore, exibir_ecommerce: valor });
  };

  const handleChangeM4uID = (valor: string) =>
    setPlanStore({ ...planStore, m4u_id: valor });

  const handleChangeDescricao = (valor: string) =>
    setPlanStore({ ...planStore, descricao: valor });

  const handleChangeCorrespondente = (valor: string) =>
    setPlanStore({ ...planStore, correspondente: valor });

  const handleChangeSumarioUrl = (valor: string) =>
    setPlanStore({ ...planStore, sumario_url: valor });

  const handleChangeAtaqueUrl = (valor: string) =>
    setPlanStore({ ...planStore, ataque_url: valor });

  const handleChangeDefesaUrl = (valor: string) =>
    setPlanStore({ ...planStore, defesa_url: valor });

  const handleChangeUploadPDF = (files: FileList | null) => {
    if (files) {
      setPlanStore({ ...planStore, regulamento_plano: files[0] });
    }
  };

  const navigate = useNavigate();
  const { addToast } = useToast();

  const handleSave = useCallback(() => {
    const formData = new FormData();

    formData.append(
      'plan_form',
      JSON.stringify({
        ...planStore,
        correspondente:
          planStore.correspondente === '' ? null : planStore.correspondente,
      }),
    );

    if (planStore.regulamento_plano) {
      formData.append('regulamento_plano', planStore.regulamento_plano);
    }

    setSaveLoading(true);
    api
      .post(`/core/planos/atualizar/${planStore.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(({ data: response }) => {
        mutate(response, true);
        setSaveLoading(false);
        addToast({
          type: 'success',
          title: 'Plano editado com sucesso',
        });
      })
      .catch(error => {
        addToast({
          type: 'error',
          title: 'Erro ao editar plano',
          description: error?.data?.message,
        });
        setSaveLoading(false);
      });
  }, [planStore, mutate, addToast]);

  const handleDownload = () => {
    setLoadingDownload(true);
    const parameters = {
      tim_id: planStore.tim_id,
      path_regulamento: 'regulamento',
      time: new Date().getTime(),
    };
    api
      .get('/core/pdf/download-regulamento', {
        responseType: 'blob',
        params: parameters,
      })
      .then(response => {
        const filename = `regulamento-${planStore.tim_id}`;

        const blob = new Blob([response.data], { type: 'application/pdf' });

        downloadPdfFile({ filename, blob });
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'Erro ao baixar PDF.',
        });
      })
      .finally(() => {
        setLoadingDownload(false);
      });
  };

  return {
    id,
    planStore,
    plansResponse,
    loyalties,
    packages,
    benefits,
    loading,
    saveLoading,
    correspondingLoading,
    loadingDownload,
    loyaltyFiltered,
    packagesFiltered,
    benefitsFiltered,
    isOpenDialogPlanFidelity,
    isOpenDialogPlanBenefit,
    isOpenDialogPlanPackage,
    classes,
    fileRef,
    isTableOrLess,
    setPlanStore,
    setIsOpenDialogPlanFidelity,
    setIsOpenDialogPlanBenefit,
    setIsOpenDialogPlanPackage,
    setCorrespondingLoading,
    handleChangeNome,
    handleChangeTimID,
    handleChangeStatus,
    handleChangeTravarSincronia,
    handleChangeExibirEcommerce,
    handleChangeM4uID,
    handleChangeDescricao,
    handleChangeCorrespondente,
    handleChangeSumarioUrl,
    handleChangeAtaqueUrl,
    handleChangeDefesaUrl,
    handleChangeUploadPDF,
    handleSave,
    handleDownload,
    navigate,
  };
}
