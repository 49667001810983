import { colors } from '@mui/material';

const white = '#FFFFFF';
const black = '#000000';

export default {
  black,
  white,
  darkGray: '#3f414b',

  primary: {
    contrastText: white,
    dark: colors.indigo[900],
    main: '#050c27',
    light: colors.indigo[100],
    gray: 'rgba(5, 12, 39, 0.6)',
  },
  secondary: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue.A400,
    light: colors.blue.A400,
  },
  success: {
    contrastText: '#2E7D32',
    dark: '#388E3C',
    main: '#4CAF50',
    light: '#C8E6C9',
  },
  info: {
    contrastText: white,
    dark: colors.blue[900],
    main: colors.blue[600],
    light: '#00C2FF',
    smooth: colors.blue[100],
  },
  warning: {
    contrastText: '#FF6F00',
    dark: '#FFA000',
    main: '#FFC107',
    light: '#FFECB3',
  },
  error: {
    contrastText: '#B71C1C',
    dark: '#D32F2F',
    main: '#F44336',
    light: '#FFCDD2',
  },
  offline: {
    contrastText: '#424242',
    dark: '#616161',
    main: '#9E9E9E',
    light: '#E0E0E0',
  },
  text: {
    primary: colors.blueGrey[900],
    secondary: colors.blueGrey[600],
    link: colors.blue[600],
  },
  background: {
    default: '#FFFFF',
    paper: white,
  },
  shadow: {
    primary: '#0000001a',
  },
  status: {
    new: {
      color: '#3A64CE',
      backgroud: '#EBEFFA',
    },
    stardardFlow: {
      color: '#5C940D',
      backgroud: '#EFF4E7',
    },
    generalFailures: {
      color: '#C92A2A',
      backgroud: '#FAEAEA',
    },
    chipProblems: {
      color: '#585858',
      backgroud: '#EEEEEE',
    },
    deliveryProblems: {
      color: '#CE6F00',
      backgroud: '#FAF1E5',
    },
    leadProblems: {
      color: '#862E9C',
      backgroud: '#F3EAF5',
    },
    portability: {
      color: '#0B7285',
      backgroud: '#E7F1F3',
    },
  },
  icon: colors.blueGrey[600],
  divider: colors.grey[200],
  controle: '#00408C',
  express: '#546E7A',
  postPago: '#0B7285',
  prePago: '#4bc0c0',
  media: '#00C2FF',
  noSale: '#C92A2A',
  reprocessed: '#E5B300',

  total: '#0f2373',

  sms: '#CC2E8D',
  smsAbandono: '#F3CDE4',
  organico: '#CC3238',
  google: '#9B2DAB',
  email: '#5746AD',
  facebook: '#3750B0',
  landingpage: '#3869D9',
  whatsApp: '#21796E',
  whatsAppMeta: '#568259',
  whatsAppLPTIM: '#0f571c',
  whatsAppOutros: '#5C940D',
  afiliado: '#E5B300',

  d: '#4bc0c0',
  d_1: '#d90429',
  d_7: '#08605f',
  d_14: '#0f571c',
  d_21: '#9a4c95',
  d_28: '#ff7f11',

  borderWhite: '#daddec',

  blueB: '#0608ff',
  // $blue-c: #36a2eb;
  // $blue-d: #8da7be;

  // $green-d: #568259;
  // $brown-a: #bb4430;
  // $orange-a: ;
};
