import { Search } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from '@mui/material';
import { useState } from 'react';

interface FiltersProps {
  setSearch(search: string): void;
  setType(type: string): void;
}

function Filters({ setSearch, setType }: FiltersProps) {
  const [localSearch, setLocalSearch] = useState('');
  const [localType, setLocalType] = useState('');

  const onFilter = () => {
    setSearch(localSearch);
    setType(localType);
  };
  return (
    <Grid
      container
      spacing={2}
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid item md={8} xs={12}>
        <FormControl variant="outlined" margin="none" size="small" fullWidth>
          <InputLabel>Buscar</InputLabel>
          <OutlinedInput
            value={localSearch}
            label="Buscar"
            onKeyUp={e => {
              if (e.key === 'Enter') {
                setLocalSearch(localSearch);
              }
            }}
            onChange={e => {
              if (e.target.value === '') {
                setLocalSearch('');
              }
              setLocalSearch(e.target.value as string);
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => {
                    setLocalSearch(localSearch);
                  }}
                  edge="end"
                  disabled
                >
                  <Search />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </Grid>
      <Grid item md={3} xs={12}>
        <TextField
          label="Tipo"
          variant="outlined"
          select
          InputLabelProps={{
            shrink: true,
          }}
          SelectProps={{ native: true }}
          size="small"
          fullWidth
          onChange={e => {
            setLocalType(e.target.value);
          }}
          value={localType}
        >
          <option value="">Selecionar</option>
          <option value="CPF">CPF</option>
          <option value="EMAIL">E-mail</option>
          <option value="CIDADE">Cidade</option>
          <option value="CEP">CEP</option>
          <option value="MSISDN">Msisdn</option>
        </TextField>
      </Grid>
      <Grid item md="auto" xs={12}>
        <Button variant="outlined" color="primary" fullWidth onClick={onFilter}>
          Buscar
        </Button>
      </Grid>
    </Grid>
  );
}

export default Filters;
