import { useState } from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { useToast } from 'hooks/toast';
import { ButtonProgress, ContainerForm } from 'components/StyledComponents';
import api from 'services/api';

import { Sale } from '../types';

interface CrosscoreAnalysisProps {
  sale: Sale;
  setSale(sale: Sale): void;
}
function CrosscoreAnalysis({ sale, setSale }: CrosscoreAnalysisProps) {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { addToast } = useToast();

  const handleSubmit = () => {
    setLoading(true);
    const url = '/core/vendas/atualizar-detalhe';

    return api
      .put(`${url}/${sale?.id}`, {
        status: 'ANALISE_CROSSCORE',
      })
      .then(() => {
        addToast({
          type: 'success',
          title: 'Sucesso!',
          description: 'Analise Crosscore disparada com Sucesso!',
        });
      })
      .catch(error => {
        error.data &&
          addToast({
            type: 'error',
            title: 'Erro ao disparar analise Crosscore',
            description: error.data.message,
          });
      })
      .finally(() => {
        api.get(`core/vendas/${sale.id}`).then(({ data }) => {
          setSale({ ...sale, ...data });
          setLoading(false);
          setOpen(false);
        });
      });
  };
  return (
    <ContainerForm>
      <Button
        type="button"
        variant="contained"
        color="primary"
        disabled={loading}
        fullWidth
        size="large"
        onClick={() => setOpen(true)}
      >
        Disparar analise Crosscore
        {loading && <ButtonProgress size={24} />}
      </Button>

      <Dialog
        open={open}
        keepMounted
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          Deseja realmente disparar uma analise Crosscore?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Fechar
          </Button>
          <Button
            onClick={() => {
              handleSubmit();
            }}
            color="primary"
          >
            Disparar
          </Button>
        </DialogActions>
      </Dialog>
    </ContainerForm>
  );
}

export default CrosscoreAnalysis;
