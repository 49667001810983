import api from 'services/api';
import useSWR from 'swr';
import { useState } from 'react';
import { BrokerLog } from '../models/brokerLog';

export const BrokerLogsFetch = () => {
  const [loading, setLoading] = useState(true);
  const { data, mutate } = useSWR(
    `broker-logs`,
    async (): Promise<BrokerLog[]> => {
      const response = await api.get(`/core/logs-rebalanceamento/recentes`);
      return response.data as BrokerLog[];
    },
    {
      onSuccess: () => setLoading(false),
      onError: () => setLoading(false),
    },
  );

  return { data, mutate, loading };
};
