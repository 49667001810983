import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material';
import { ButtonProgress } from 'components/StyledComponents';
import useBreakpoint from 'hooks/useBreakpoint';
import api from 'services/api';
import { useToast } from 'hooks/toast';
import AddIcCallIcon from '@mui/icons-material/AddIcCall';
import { Container } from './style';
import { useTotalizersFetch } from '../../hooks/useTotalizersFetch';

function EditTPSForm() {
  const [tps, setTPS] = useState('');
  const [showEditionWindow, setShowEditionWindow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formIsValid, setFormIsValid] = useState(false);
  const { isOnBreakpoint: isTabletSizeOrSmaller } = useBreakpoint(1024);
  const { addToast } = useToast();
  const key = 'BROKER_TPS_ALVO';
  const { updateTotalizersData } = useTotalizersFetch();

  useEffect(() => {
    setFormIsValid(!!tps);
  }, [tps]);

  const handleUpdate = () => {
    setLoading(true);
    api
      .put(`/api/variaveis-globais/${key}`, {
        value: tps,
      })
      .then(async () => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Edição concluída',
          description: 'O TPS alvo foi atualizado com sucesso!',
        });

        setShowEditionWindow(false);
        await updateTotalizersData();
      })
      .catch(error => {
        let message = error.data.errors[0]?.message;
        if (!message) {
          message = error.data.message;
        }
        message &&
          addToast({
            type: 'error',
            title: 'Erro ao atualizar o TPS alvo.',
            description: message,
          });
        setLoading(false);
      });
  };

  return (
    <Container>
      <IconButton onClick={() => setShowEditionWindow(true)}>
        <AddIcCallIcon />
      </IconButton>
      <Dialog
        open={showEditionWindow}
        onClose={() => setShowEditionWindow(false)}
        aria-labelledby="edit-tps"
        fullScreen={isTabletSizeOrSmaller}
      >
        <form>
          <Box padding="20px">
            <CardHeader
              id="edit-tps"
              title="Editar TPS Alvo"
              subheader="O TPS alvo será atualizado"
              style={{ padding: 10, paddingBottom: 19 }}
            />
            <CardContent>
              <TextField
                value={tps}
                aria-readonly
                fullWidth
                label="TPS Alvo"
                margin="dense"
                name="protocolo"
                onKeyPress={event => {
                  if (/[0-9]/.test(event.key)) {
                    return;
                  }
                  event.preventDefault();
                }}
                onChange={e => setTPS(e.target.value)}
                variant="outlined"
                style={{ paddingBottom: 10 }}
              />
            </CardContent>
            <DialogActions
              style={{
                paddingTop: 14,
                justifyContent: 'space-between',
                paddingRight: 15,
                paddingLeft: 15,
              }}
            >
              <Button
                onClick={() => {
                  setShowEditionWindow(false);
                }}
                type="button"
                variant="contained"
                disabled={loading}
                style={{ backgroundColor: 'white', color: '#C92A2A' }}
              >
                Cancelar
              </Button>
              <Button
                onClick={handleUpdate}
                color="primary"
                type="button"
                variant="contained"
                disabled={!formIsValid || loading}
              >
                Salvar
                {loading && <ButtonProgress size={24} />}
              </Button>
            </DialogActions>
          </Box>
        </form>
      </Dialog>
    </Container>
  );
}

export default EditTPSForm;
