import { Button, CardHeader, Divider } from '@mui/material';
import { Card, Header } from 'components/StyledComponents';
import { Refresh } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import { CardContent } from './styles';
import Totalizer from './Totalizer';
import LogList from './LogList';
import { BrokerLogsFetch } from '../../fetches/brokerLogsFetch';
import BrokerDataCard from './BrokerDataCard';
import { BrokerDataFetch } from '../../fetches/brokerDataFetch';
import palette from '../../theme/palette';
import ToogleAutoRebalancing from './ToogleAutoRebalancing';

function BrokerMonitoring() {
  const { data: logs, loading: loadingLogs } = BrokerLogsFetch();
  const [cacheIgnore, setCacheIgnore] = useState(false);
  const {
    data: brokerData,
    mutate: mutateBrokerData,
    loading: loadingBrokerData,
    setLoading: setLoadingBrokerData,
  } = BrokerDataFetch(cacheIgnore);

  useEffect(() => {
    if (cacheIgnore) {
      setLoadingBrokerData(true);
      mutateBrokerData();
      setCacheIgnore(false);
    }
  }, [cacheIgnore, mutateBrokerData, setLoadingBrokerData]);

  const handleRefresh = () => {
    setCacheIgnore(true);
  };

  return (
    <Card>
      <Header>
        <CardHeader
          title="Acompanhamento do Broker"
          subheader="Painel de acompanhamento para as métricas e rebalanceamentos do broker."
        />
        <ToogleAutoRebalancing />
      </Header>
      <CardContent style={{ marginTop: 16 }}>
        <Totalizer />
      </CardContent>
      <Divider />
      <Header>
        <CardHeader
          title="Dados Correntes do Broker"
          style={{ padding: '16px 0px 32px 16px' }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleRefresh}
          style={{ margin: '0 32px 16px 0' }}
        >
          <Refresh style={{ color: palette.white }} />
        </Button>
      </Header>
      <CardContent>
        <BrokerDataCard data={brokerData} loading={loadingBrokerData} />
      </CardContent>
      <Divider />
      <Header>
        <CardHeader
          title="Logs dos ultimos rebalanceamentos"
          style={{ padding: '16px 0px 0px 16px' }}
        />
      </Header>
      <CardContent>
        <LogList logs={logs} loading={loadingLogs} />
      </CardContent>
    </Card>
  );
}

export default BrokerMonitoring;
