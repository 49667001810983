import userSWR from 'swr';
import api from '../services/api';
import { HealthData } from '../pages/ManagementReport/reports/HealthCheckPanel/cardConfig';

export const HealthCheckStatusFetch = (mechanism: string) => {
  const { data, mutate } = userSWR<HealthData>(
    `setup_${mechanism}`,
    async (): Promise<HealthData> => {
      const { data: response } = await api.get(
        `core/health-check/${mechanism}`,
      );

      return response as HealthData;
    },
  );

  return { data, mutate };
};
