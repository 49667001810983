import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import useStyles from './styles';
import CardTypes, { CustomButtons, Types } from './types';
import DispatchRebalancingForm from './DispatchRebalancingForm';
import EditWorkersForm from './EditWorkersForm';
import EditTPSForm from './EditTPSForm';

interface CardTotalizerProps {
  type: Types;
  subtitle: string;
  title: string;
  data?: string;
  customButton: CustomButtons;
}

const customButtonMap = {
  'editar-robos': <EditWorkersForm />,
  'editar-tps': <EditTPSForm />,
  'disparar-balanceamento': <DispatchRebalancingForm />,
};

function CardTotalizer({
  type,
  subtitle,
  title,
  data,
  customButton,
}: CardTotalizerProps) {
  const classes = useStyles();

  const { icon: Icon, color } = CardTypes[type];
  return (
    <Card className={classes.root}>
      <Box className={classes.box}>
        <Icon fill={color} />
      </Box>
      <Box className={classes.content}>
        <Grid item className={classes.contentTotal}>
          <Box className={classes.boxTotal}>
            <Typography
              className={classes.title}
              color={CardTypes[type].color}
              variant="body2"
            >
              {subtitle}
            </Typography>
            <Typography className={classes.subtitle} variant="subtitle1">
              {title}
            </Typography>
          </Box>
          <Typography
            className={classes.h3}
            color={CardTypes[type].color}
            variant="h3"
          >
            {(data && ((data !== '0' && data !== '0.0' && data) || '-')) || (
              <Skeleton variant="rectangular" width={80} height={30} />
            )}
          </Typography>
        </Grid>
        {customButton && customButtonMap[customButton] && (
          <Box className={classes.boxTopIcon}>
            {customButtonMap[customButton]}
          </Box>
        )}
      </Box>
    </Card>
  );
}

export default CardTotalizer;
