import {
  FiBox,
  FiLayers,
  FiMessageSquare,
  FiSettings,
  FiShoppingBag,
  FiUsers,
  FiVolume2,
} from 'react-icons/fi';
import {
  basesAfiliados,
  cmsLP,
  correcaoVendas,
  curadorias,
  dominios,
  flashSale,
  gestaoAcessos,
  gestaoFuncao,
  gestaoPacotes,
  gestaoSetups,
  gestaoSIMCards,
  gestaoUsuarios,
  linksOfertas,
  operacao,
  perfil,
  portabilidadeTratamento,
  portabilidadeValidar,
  relatorioAbandonoFluxo,
  relatorioDeErros,
  relatorioGerencial,
  relatoriosEspecias,
  transacoes,
  tratamentoErros,
  vendedorVirtual,
  gestaoPlanos,
  avaliacoes,
  gestaoManual,
  gestaoMeta,
  execucaoElegibilidade,
  errosIntegracao,
  distribuicaoUtm,
  definicaoDeUtm,
  dadosBlacklist,
  acompanhamentoBroker,
  reprocessamentoTotal,
} from 'constants/permissions';

import ManagementReport from 'pages/ManagementReport';
import Sale from 'pages/Sale';
import SpecialReports from 'pages/SpecialReports';
import SIMCardStock from 'pages/SIMCardStock/Dashboard';
import FraudKeeper from 'pages/FraudKeeper';
import FraudDetail from 'pages/FraudKeeper/FraudDetail';
import Operation from 'pages/Operation';
import Domain from 'pages/Domain';
import SIMCardBatchDetails from 'pages/SIMCardStock/Details';
import BatchDetails from 'pages/SIMCardStock/BatchDetails';
import ErrorTreatment from 'pages/ErrorTreatment';
import ManagerRole from 'pages/ManagerRole';
import NewRole from 'pages/ManagerRole/NewRole';
import EditRole from 'pages/ManagerRole/EditRole';
import PortabilityDayByDay from 'pages/Portability/PortabilityDayByDay';
import PortabilityFinalRescheduling from 'pages/Portability/PortabilityFinalRescheduling';
import PortabilityValidation from 'pages/Portability/PortabilityValidation';
import PortabilityInTreatment from 'pages/Portability/PortabilityInTreatment';
import OfferDefinition from 'pages/OfferDefinition';
import UserJourneyReport from 'pages/UserJourneyReport';
import AffiliateBase from 'pages/AffiliateBase';
import VirtualSeller from 'pages/VirtualSeller';
import Profile from 'pages/Profile';
import ManagerUsers from 'pages/ManagerUsers';
import ManagerAccess from 'pages/ManagerAccess';
import ManageSetup from 'pages/ManageSetup';
import BugReport from 'pages/BugReport';
import ManagerPackage from 'pages/ManagerPackage';
import NewOfferDefinition from 'pages/OfferDefinition/NewOfferDefinition';
import CreateFromOfferDefinition from 'pages/OfferDefinition/OfferDefinitionList/CreateFromOfferDefinition';
import EditOfferDefinition from 'pages/OfferDefinition/OfferDefinitionList/EditOfferDefinition';
import CmsLP from 'pages/CmsLP';
import SalesCorrection from 'pages/SalesCorrection';
import EditSaleCorrection from 'pages/SalesCorrection/EditSaleCorrection';
import OfferLinks from 'pages/OffersLinks';
import NewOfferLink from 'pages/OffersLinks/components/NewOfferLink';
import EditOfferLink from 'pages/OffersLinks/components/EditOfferLink';
import ManagerPlan from 'pages/ManagerPlan';
import EditPlan from 'pages/ManagerPlan/EditPlan';
import PlanPricingTable from 'pages/ManagerPlan/EditPlan/PlanPricingTable';
import LoyaltyPriceTable from 'pages/ManagerPlan/EditPlan/FidelityList/FidelityListItem/LoyaltyPriceTable';
import ManagerBenefits from 'pages/ManagerBenefits';
import FormEditUser from 'pages/ManagerUsers/components/FormEditUser';
import SatisfactionSurvey from 'pages/SatisfactionSurvey/index';
import Manual from 'pages/Manual';
import ManagerGoal from 'pages/ManagerGoal';
import ExecuteEligibility from 'pages/ManagerPlan/ExecuteEligibility';
import UtmDefinition from 'pages/UtmDefinition';
import EditUtmConfiguration from 'pages/UtmDefinition/components/EditUtmConfiguration';
import NewUtmConfiguration from 'pages/UtmDefinition/components/NewUtmConfiguration';
import Engaging from 'pages/Engaging';
import BaseView from 'pages/Engaging/components/EngagingList/components/BaseView';
import IntegrationErrors from 'pages/IntegrationErrors';
import UtmDistribution from 'pages/UtmDistribution';
import BlacklistData from 'pages/BlacklistData';
import NewBlacklistData from 'pages/BlacklistData/components/NewBlacklistData';
import EditBlacklistData from 'pages/BlacklistData/components/EditBlacklistData';
import UtmDistributionByID from '../../../../UtmDistributionByID';
import BrokerMonitoring from '../../../../BrokerMonitoring';

const pages = [
  {
    id: 1,
    title: 'Vendas',
    icon: <FiShoppingBag />,
    subModules: [
      {
        moduleID: 1,
        title: 'Relatório Gerencial',
        href: '/relatorio-gerencial',
        component: ManagementReport,
        permission: relatorioGerencial,
        showMenu: true,
      },
      {
        moduleID: 1,
        title: 'Transações',
        href: '/transacoes',
        component: Sale,
        permission: transacoes,
        showMenu: true,
      },
      {
        moduleID: 1,
        title: 'Relatórios Especiais',
        href: '/relatorios-especiais',
        component: SpecialReports,
        permission: relatoriosEspecias,
        showMenu: true,
      },
    ],
  },
  {
    id: 2,
    title: 'Operação',
    icon: <FiBox />,
    subModules: [
      {
        moduleID: 2,
        title: 'Curadoria de Fraude',
        href: '/curadoria-fraude',
        component: FraudKeeper,
        permission: curadorias,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Detalhe de Curadoria',
        href: '/curadoria-fraude/:id',
        component: FraudDetail,
        permission: curadorias,
        showMenu: false,
      },
      {
        moduleID: 2,
        title: 'Envio de Chip',
        href: '/operacao',
        component: Operation,
        permission: operacao,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Estoque de SIM cards',
        href: '/estoque-simcard',
        component: SIMCardStock,
        permission: gestaoSIMCards,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Estoque de lotes de SIM cards',
        href: '/estoque-simcard/estoque-lotes',
        component: SIMCardBatchDetails,
        permission: gestaoSIMCards,
        showMenu: false,
      },
      {
        moduleID: 2,
        title: 'Estoque de chips de um lote',
        href: '/estoque-simcard/estoque-lotes-simcards',
        component: BatchDetails,
        permission: gestaoSIMCards,
        showMenu: false,
      },
      {
        moduleID: 2,
        title: 'Tratamento de Erros',
        href: '/tratamento-erros',
        component: ErrorTreatment,
        permission: tratamentoErros,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Visualizar o Domínio',
        href: '/dominios',
        component: Domain,
        permission: dominios,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Correção de Vendas',
        href: '/correcao-vendas',
        component: SalesCorrection,
        permission: correcaoVendas,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Correção de Vendas',
        href: '/correcao-vendas/edita-venda/:id',
        component: EditSaleCorrection,
        permission: correcaoVendas,
        showMenu: false,
      },
      {
        moduleID: 2,
        title: 'Execução de Elegibilidade',
        href: '/execucao-elegibilidade',
        component: ExecuteEligibility,
        permission: execucaoElegibilidade,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Dados Blacklist',
        href: '/dados-blacklist',
        component: BlacklistData,
        permission: dadosBlacklist,
        showMenu: true,
      },
      {
        moduleID: 2,
        title: 'Dados Blacklist',
        href: '/dados-blacklist/cadastro-dados-blacklist',
        component: NewBlacklistData,
        permission: dadosBlacklist,
        showMenu: false,
      },
      {
        moduleID: 2,
        title: 'Dados Blacklist',
        href: '/dados-blacklist/edita-dado-blacklist/:id',
        component: EditBlacklistData,
        permission: dadosBlacklist,
        showMenu: false,
      },
    ],
  },
  {
    id: 3,
    title: 'Portabilidade',
    icon: <FiUsers />,
    subModules: [
      {
        moduleID: 3,
        title: 'Reagendamento',
        href: '/portabilidade-dia-dia',
        component: PortabilityDayByDay,
        permission: portabilidadeValidar,
        showMenu: true,
      },
      {
        moduleID: 3,
        title: 'Ajuste Final',
        href: '/portabilidade-reagendamento-final',
        component: PortabilityFinalRescheduling,
        permission: portabilidadeValidar,
        showMenu: true,
      },
      {
        moduleID: 3,
        title: 'Validação',
        href: '/validar-portabilidade',
        component: PortabilityValidation,
        permission: portabilidadeValidar,
        showMenu: true,
      },
      {
        moduleID: 3,
        title: 'Tratamento',
        href: '/tratamento-portabilidade',
        component: PortabilityInTreatment,
        permission: portabilidadeTratamento,
        showMenu: true,
      },
    ],
  },
  {
    id: 4,
    title: 'Marketing',
    icon: <FiVolume2 />,
    subModules: [
      {
        moduleID: 4,
        title: 'Definição de Oferta',
        href: '/flash-sales',
        component: OfferDefinition,
        permission: flashSale,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Definição de Oferta',
        href: '/flash-sales/cadastro-oferta',
        component: NewOfferDefinition,
        permission: flashSale,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Definição de Oferta',
        href: '/flash-sales/edita-oferta/:id',
        component: EditOfferDefinition,
        permission: flashSale,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Definição de Oferta',
        href: '/flash-sales/cria-apartir/:id',
        component: CreateFromOfferDefinition,
        permission: flashSale,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Abandono de Fluxo',
        href: '/abandono-fluxo',
        component: UserJourneyReport,
        permission: relatorioAbandonoFluxo,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Avaliações',
        href: '/avaliacoes',
        component: SatisfactionSurvey,
        permission: avaliacoes,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'CMS LP',
        href: '/cms-lp',
        component: CmsLP,
        permission: cmsLP,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Bases de Afiliados',
        href: '/bases-afiliados',
        component: AffiliateBase,
        permission: basesAfiliados,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Links de Ofertas',
        href: '/links-ofertas',
        component: OfferLinks,
        permission: linksOfertas,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Links de Ofertas',
        href: '/links-ofertas/cadastro-link',
        component: NewOfferLink,
        permission: linksOfertas,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Links de Ofertas',
        href: '/links-ofertas/edita-link/:id',
        component: EditOfferLink,
        permission: linksOfertas,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Definição de UTM',
        href: '/definicao-utm/',
        component: UtmDefinition,
        permission: definicaoDeUtm,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Definição de UTM',
        href: '/definicao-utm/sources/:id',
        component: UtmDefinition,
        permission: definicaoDeUtm,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Editar Configuração de UTM',
        href: '/definicao-utm/edita-configuracao/:id',
        component: EditUtmConfiguration,
        permission: definicaoDeUtm,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Cadastrar Configuração de UTM',
        href: '/definicao-utm/cadastro-configuracao',
        component: NewUtmConfiguration,
        permission: definicaoDeUtm,
        showMenu: false,
      },
      {
        moduleID: 4,
        title: 'Distribuição UTM',
        href: '/distribuicao-utm/',
        component: UtmDistribution,
        permission: distribuicaoUtm,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Distribuição UTM por ID',
        href: '/distribuicao-utm-id/',
        component: UtmDistributionByID,
        permission: distribuicaoUtm,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Engajador',
        href: '/reprocessamento-total',
        component: Engaging,
        permission: reprocessamentoTotal,
        showMenu: true,
      },
      {
        moduleID: 4,
        title: 'Visualização de base',
        href: '/reprocessamento-total/visualiza-base/:id',
        component: BaseView,
        permission: distribuicaoUtm,
        showMenu: false,
      },
    ],
  },
  {
    id: 5,
    title: 'Broker',
    icon: <FiMessageSquare />,
    subModules: [
      {
        moduleID: 5,
        title: 'Acompanhamento',
        href: '/acompanhamento-broker',
        component: BrokerMonitoring,
        permission: acompanhamentoBroker,
        showMenu: true,
      },
    ],
  },
  {
    id: 6,
    title: 'Planos',
    icon: <FiLayers />,
    subModules: [
      {
        moduleID: 6,
        title: 'Listagem',
        href: '/gestao-planos',
        component: ManagerPlan,
        permission: gestaoPlanos,
        showMenu: true,
      },
      {
        moduleID: 6,
        title: 'Editar Plano',
        href: '/gestao-planos/edita-plano/:id',
        component: EditPlan,
        permission: gestaoPlanos,
        showMenu: false,
      },
      {
        moduleID: 6,
        title: 'Tabela de preços do plano',
        href: '/gestao-planos/tabela-precos-plano/:id',
        component: PlanPricingTable,
        permission: gestaoPlanos,
        showMenu: false,
      },
      {
        moduleID: 6,
        title: 'Tabela de preços de fidelidade',
        href: '/gestao-planos/tabela-precos-fidelidade/:id',
        component: LoyaltyPriceTable,
        permission: gestaoPlanos,
        showMenu: false,
      },
      {
        moduleID: 6,
        title: 'Benefícios',
        href: '/gestao-planos/beneficios/:id',
        component: ManagerBenefits,
        permission: gestaoPlanos,
        showMenu: false,
      },
      {
        moduleID: 6,
        title: 'Benefícios',
        href: '/gestao-beneficios/',
        component: ManagerBenefits,
        permission: gestaoPlanos,
        showMenu: true,
      },
      {
        moduleID: 6,
        title: 'Pacotes',
        href: '/gestao-pacotes',
        component: ManagerPackage,
        permission: gestaoPacotes,
        showMenu: true,
      },
    ],
  },
  {
    id: 7,
    title: 'Gestão',
    icon: <FiSettings />,
    subModules: [
      {
        moduleID: 7,
        title: 'Gestão de Função',
        href: '/gestao-funcao',
        component: ManagerRole,
        permission: gestaoFuncao,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Cadastrar Função',
        href: '/gestao-funcao/cadastro-funcao',
        component: NewRole,
        permission: gestaoFuncao,
        showMenu: false,
      },
      {
        moduleID: 7,
        title: 'Editar Função',
        href: '/gestao-funcao/edita-funcao/:id',
        component: EditRole,
        permission: gestaoFuncao,
        showMenu: false,
      },
      {
        moduleID: 7,
        title: 'Gestão de Usuário',
        href: '/gestao-usuarios',
        component: ManagerUsers,
        permission: gestaoUsuarios,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Gestão de Usuário',
        href: '/gestao-usuarios/edita-usuario/:id',
        component: FormEditUser,
        permission: gestaoUsuarios,
        showMenu: false,
      },
      {
        moduleID: 7,
        title: 'Gestão de Meta',
        href: '/gestao-meta',
        component: ManagerGoal,
        permission: gestaoMeta,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Gestão de Setups',
        href: '/gestao-setups',
        component: ManageSetup,
        permission: gestaoSetups,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Relatório de Erros',
        href: '/relatorio-erros',
        component: BugReport,
        permission: relatorioDeErros,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Acessos',
        href: '/gestao-acessos',
        component: ManagerAccess,
        permission: gestaoAcessos,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Vendedor Virtual',
        href: '/vendedor-virtual',
        permission: vendedorVirtual,
        component: VirtualSeller,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Perfil',
        href: '/perfil',
        permission: perfil,
        component: Profile,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Erros de Integração',
        href: '/erros-integracao',
        permission: errosIntegracao,
        component: IntegrationErrors,
        showMenu: true,
      },
      {
        moduleID: 7,
        title: 'Manual',
        href: '/manual',
        permission: gestaoManual,
        component: Manual,
        showMenu: true,
      },
    ],
  },
];

export default pages;
