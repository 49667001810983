import { WorkersRunning } from 'models/workersRunning';
import api from 'services/api';
import userSWR from 'swr';

export const MessengerWorkersRunningFetch = (processName: string) => {
  const { data, mutate: mutateWorkers } = userSWR<WorkersRunning>(
    `messenger_workers_running_batch`,
    async (): Promise<WorkersRunning> => {
      const { data: total } = await api.get(
        `integrations/messenger/workers-rodando/${processName}`,
      );

      return total as WorkersRunning;
    },
  );

  return { data, mutateWorkers };
};
